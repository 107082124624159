import React from 'react';

export default function TermsOfUse() {
  return (
    <div className={'container'}>
      <h1 className={'title'}>
        {'Terms and Conditions for The Divine Canine LLC'}
      </h1>
      <br />
      <section className={'section'}>
        <div className={'container'}>
          <h2 className={'subtitle'}>
            These Website Standard Terms and Conditions written on this webpage
            shall manage your use of our website, The Divine Canine accessible
            at divinecaninellc.com. These Terms will be applied fully and affect
            to your use of this Website. By using this Website, you agreed to
            accept all terms and conditions written in here. You must not use
            this Website if you disagree with any of these Website Standard
            Terms and Conditions. Minors or people below 18 years old are not
            allowed to use this Website.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Intellectual Property Rights'}</h1>
          <h2 className={'subtitle'}>
            Other than the content you own, under these Terms, The Divine Canine
            LLC and/or its licensors own all the intellectual property rights
            and materials contained in this Website. You are granted a limited
            license only for purposes of viewing the material contained on this
            Website.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Restrictions'}</h1>
          <h2 className={'subtitle'}>
            You are specifically restricted to the following:
          </h2>

          <div className={'content'}>
            <ol type={'1'}>
              <li>{'publishing any Website material in any other media;'}</li>
              <li>
                selling, sublicensing and/or otherwise commercializing any
                Website material;
              </li>
              <li>
                {'publicly performing and/or showing any Website material;'}
              </li>
              <li>
                using this Website in any way that is or may be damaging to this
                Website;
              </li>
              <li>
                using this Website in any way that impacts user access to this
                Website;
              </li>
              <li>
                using this Website contrary to applicable laws and regulations,
                or in any way may cause harm to the Website, or to any person or
                business entity;
              </li>
              <li>
                engaging in any data mining, data harvesting, data extracting or
                any other similar activity in relation to this Website;
              </li>
              <li>
                using this Website to engage in any advertising or marketing.
              </li>
            </ol>
          </div>
          <p className={'subtitle'}>
            Certain areas of this Website are restricted from being access by
            you and The Divine Canine LLC may further restrict access by you to
            any areas of this Website, at any time, in absolute discretion. Any
            user ID and password you may have for this Website are confidential
            and you must maintain confidentiality as well.
          </p>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'You Content'}</h1>
          <h2 className={'subtitle'}>
            In these Website Standard Terms and Conditions, “Your Content” shall
            mean any audio, video text, images or other material you choose to
            display on this Website. By displaying Your Content, you grant The
            Divine Canine LLC a non-exclusive, worldwide, irrevocable, sub
            licensable license to use, reproduce, adapt, publish, translate and
            distribute it in any and all media.
            <br />
            <br />
            Your Content must be your own and must not be invading any
            third-party&apos;s rights. The Divine Canine LLC reserves the right
            to remove any of Your Content from this Website at any time without
            notice.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'No warranties'}</h1>
          <h2 className={'subtitle'}>
            This Website is provided “as is” with all faults, and The Divine
            Canine LLC express no representations or warranties of any kind
            related to this Website or the materials contained on this Website.
            Also, nothing contained on this Website shall be interpreted as
            advising you.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Indemnification'}</h1>
          <h2 className={'subtitle'}>
            <strong>
              You hereby indemnify to the fullest extent The Divine Canine LLC
              from and against any and/or all liabilities, costs, demands,
              causes of action, damages and expenses arising in any way related
              to your breach of any of the provisions of these Terms.
            </strong>
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Severability'}</h1>
          <h2 className={'subtitle'}>
            If any provision of these Terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Variation of Terms'}</h1>
          <h2 className={'subtitle'}>
            The Divine Canine LLC is permitted to revise these Terms at any time
            as it sees fit, and by using this Website you are expected to review
            these Terms on a regular basis.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Entire Agreement'}</h1>
          <h2 className={'subtitle'}>
            These Terms constitute the entire agreement between The Divine
            Canine LLC and you in relation to your use of this Website, and
            supersede all prior agreements and understandings.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Governing Law & Jurisdiction'}</h1>
          <h2 className={'subtitle'}>
            These Terms will be governed by and interpreted in accordance with
            the laws of the United States, and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in the United
            States for the resolution of any disputes.
          </h2>
        </div>
      </section>
    </div>
  );
}
