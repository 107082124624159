import React from 'react';
import MapComponent from '../GoogleMap/GoogleMap';
import OperationsList from '../OperationsList';

function Map() {
  if (process.env.NODE_ENV !== 'production') {
    return <p>{'Maps disabled for development'}</p>;
  }

  return (
    <div className={'tile is-ancestor'}>
      <div className={'tile is-parent'}>
        <article className={'tile is-child notification'}>
          <div className={'content'}>
            <MapComponent />
          </div>
        </article>
      </div>
    </div>
  );
}

function MapContainer() {
  return (
    <div>
      <span id={'location'}>
        <div
          className={'container'}
          style={{ marginTop: '8em', marginBottom: '10em' }}
        >
          <OperationsList />
          <Map />
        </div>
      </span>
    </div>
  );
}

export default MapContainer;
