import React from 'react';
import '../../bulma_css.scss';

export default function OperationsList() {
  return (
    <div style={{ marginBottom: '3em' }}>
      <div className={'bd-focus'}>
        <nav className={'columns'}>
          <div className={'bd-focus-item column has-text-centered'}>
            <p className={'title is-3'}>
              <strong>{'Hours'}</strong>
            </p>
            <p
              style={{ marginBottom: '1.0em' }}
              className={'subtitle is-5 has-text-black'}
            >
              <strong>Varies by appointment</strong>
            </p>

            <figure className={'bd-focus-icon'}>
              <span className={'bd-focus-css3 has-text-warning icon is-large'}>
                <i className={'fas fa-store fa-w-16 fa-3x'}></i>
              </span>
            </figure>
          </div>

          <div className={'bd-focus-item column has-text-centered'}>
            <p className={'title is-3'}>
              <strong>{'Phone Number'}</strong>
            </p>
            <p
              style={{ marginBottom: '1.5em' }}
              className={'subtitle is-4 has-text-black'}
            >
              <strong>{'864-708-1200'}</strong>
            </p>
            <figure className={'bd-focus-icon'}>
              <span className={'bd-focus-css3 has-text-primary icon is-large'}>
                <i className={'fas fa-phone-square fa-w-16 fa-3x'}></i>
              </span>
            </figure>
          </div>

          <div className={'bd-focus-item column has-text-centered'}>
            <p className={'title is-3'}>
              <strong>{'Address'}</strong>
            </p>
            <p
              style={{ marginBottom: '1.8em' }}
              className={'subtitle is-5 has-text-black'}
            >
              <strong>{'31 N Main St Inman, SC 29349'}</strong>
            </p>
            <figure className={'bd-focus-icon'}>
              <span className={'bd-focus-css3 has-text-info icon is-large'}>
                <i className={'fas fa-map-marked-alt fa-w-16 fa-3x'} />
              </span>
            </figure>
          </div>
        </nav>
      </div>
      <div className={'hero'} />
    </div>
  );
}
