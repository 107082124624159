import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import NavMenu from '../NavBar';

class NavBarContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      navMenuClassName: '',
      isOpen: false,
    };
  }

  mobileMenuToggle() {
    if (!this.state.isOpen) {
      this.setState({
        navMenuClassName: 'is-active',
        isOpen: true,
      });
    } else {
      this.setState({
        navMenuClassName: '',
        isOpen: false,
      });
    }
  }

  render() {
    return (
      <nav
        className={`navbar is-fresh is-fixed-top no-shadow ${
          this.props.color ? ` is-${this.props.color}` : ''
        }`}
        role={'navigation'}
        aria-label={'main navigation'}
      >
        <div className={'container'}>
          <div id={'logo'} className={'navbar-start'}>
            <Link onClick={() => this.mobileMenuToggle()} to={'/'}>
              <div className={'navbar-item is-hidden-mobile'}>
                <div className={'navbar-item'}>
                  <span style={{ marginLeft: '.5em', marginTop: '0.4em' }}>
                    {'The Divine Canine'}
                  </span>
                </div>
              </div>
            </Link>
            <a
              href={'#home'}
              role={'button'}
              className={'navbar-burger'}
              onClick={() => this.mobileMenuToggle()}
              aria-label={'menu'}
              aria-expanded={'false'}
            >
              <span aria-hidden={'true'} />
              <span aria-hidden={'true'} />
              <span aria-hidden={'true'} />
            </a>
          </div>
          <div className={`navbar-menu ${this.state.navMenuClassName}`}>
            <div className={'navbar-end'}>
              <NavMenu onClick={() => this.mobileMenuToggle()} />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBarContainer;
