/* eslint-disable */
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function RouteHistoryEffect({ history }) {
  useEffect(() => {
    const unlisten = history.listen(value => {
      if (value.pathname === '/social') {
        window.location.reload();
      } else {
        window.scrollTo(0, 0);
        switch (value.pathname) {
          case '/location': {
            document.title = 'The Divine Canine | Location';
            let meta = document.getElementById('metaDesc');
            meta.content =
              'The Divine Canine is located at 31 N Main St Inman, SC 29349.';
            break;
          }
          case '/services': {
            document.title = 'The Divine Canine | Services';
            let meta = document.getElementById('metaDesc');
            meta.content =
              'Services of The Divine Canine include full grooming, baths, nail care, and ear care.';
            break;
          }
          case '/privacy-policy': {
            document.title =
              'The Divine Canine | Privacy Policy - Terms Of Use';
            let meta = document.getElementById('metaDesc');
            meta.content =
              'Privacy policy and terms of use for the Divine Canine LLC.';
            break;
          }
          default: {
            document.title = 'The Divine Canine LLC';
            let meta = document.getElementById('metaDesc');
            meta.content =
              'The Divine Canine provides exceptional dog care and grooming services located in Inman, SC.';
            break;
          }
        }
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(RouteHistoryEffect);
