import React from 'react';
import Section from '../Section';
import SectionHeader from '../SectionHeader';
import SectionButton from '../SectionButton';
import './styles.scss';

function HeroButton(props) {
  if (props.showing === false) {
    return null;
  }
  return (
    <SectionButton
      parentColor={props.color}
      size={props.buttonSize}
      onClick={props.buttonOnClick}
      href={props.href}
      to={props.to}
    >
      {props.buttonText}
    </SectionButton>
  );
}

function HeroSection(props) {
  return (
    <Section id={props.ident} color={props.color} size={props.size}>
      <div className={'container'}>
        <div className={'columns is-vcentered is-desktop'}>
          <div className={'column is-5-desktop has-text-centered-touch'}>
            <SectionHeader
              title={props.title}
              subtitle={props.subtitle}
              size={props.sectionSize}
            />
            <HeroButton
              isLink={props.isLink}
              link={props.link}
              parentColor={props.color}
              size={props.buttonSize}
              onClick={props.buttonOnClick}
              href={props.href}
              buttonText={props.buttonText}
              showing={props.showing}
              to={props.to}
            />
          </div>
          <div className={'column is-1'} />
          <div className={'column'}>
            <figure className={'HeroSection_image image'}>
              <img style={props.imageStyle} src={props.image} alt={'Logo'} />
            </figure>
          </div>
        </div>
      </div>
    </Section>
  );
}

export default HeroSection;
