import React from 'react';
import logo from './components/assets/logo-clear.png';
import puppies from './components/assets/puppies-banner.jpg';
import './styles.scss';
import HeroSection from './components/HeroSection';
import SectionHeader from './components/SectionHeader';
import PresentationCard from './components/PresentationCard';

function CoreValues() {
  return (
    <div className={'section is-medium is-light'}>
      <div className={'container'}>
        <div className={'container'}>
          <div className={'columns'}>
            <div className={'column is-6 is-offset-3'}>
              <SectionHeader
                title={'Dog Care Services That Go The Extra Mile'}
                subtitle={''}
                centered
                size={3}
              />

              <PresentationCard
                iconColor={'has-text-primary'}
                icon={'fas fa-dog fa-3x'}
                title={'Personal Care That Every Dog Deserves'}
                body={
                  "The health and happiness of your pet are instilled in our core values.\
                  Our experienced groomers love dogs. They ensure each visit is relaxing and\
                  calm and tailored specifically to your pet's needs."
                }
              />

              <PresentationCard
                iconColor={'has-text-info'}
                icon={'fas fa-store-alt fa-3x'}
                title={'Clean and Sanitary Facilities'}
                body={
                  "We realize how important your pet's safety and health is.\
                   We take pride in providing a hygienic and clean environment to promote good health."
                }
              />

              <PresentationCard
                iconColor={'has-text-dark'}
                icon={'far fa-calendar-check fa-3x'}
                title={'Scheduling'}
                to={'/location'}
                link={'Contact'}
                body={'us to arrange an appointment.'}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function App() {
  return (
    <div>
      <HeroSection
        ident={'home'}
        to={'/services'}
        color={'light'}
        size={'medium'}
        title={'The Divine Canine'}
        subtitle={
          <div>
            <p>Grooming and dog care services located in Inman SC</p>
            <br />
            <span>
              {' '}
              <i className={'fas fa-check'}></i> Set Appointments
            </span>
            <br />
            <span>
              {' '}
              <i className={'fas fa-check'}></i> Professional stylists and
              groomers
            </span>
            <br />
            <span>
              {' '}
              <i className={'fas fa-check'}></i> Tailored care specific to your
              dog's needs
            </span>
          </div>
        }
        buttonText={'See Our Services'}
        image={logo}
        sectionSize={1}
        buttonSize={'medium'}
        showing
      />

      <div className={'hero is-fullheight is-transparent has-background'}>
        <img
          alt={'Puppies'}
          className={'hero-background is-transparent'}
          src={puppies}
        />
        <div className={'hero-body'}>
          <CoreValues />
        </div>
      </div>
    </div>
  );
}
