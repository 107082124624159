import React from 'react';
//import './styles.scss';
import SectionHeader from '../SectionHeader';
import FacebookPost from './Post';

export default function FacebookPostSection() {
  document.title = 'The Divine Canine | Social';
  let meta = document.getElementById('metaDesc');
  meta.content =
    'Connect with us on social media and see why people love The Divine Canine.';
  return (
    <div style={{ marginTop: '5em' }}>
      <SectionHeader
        title={'See why people love The Divine Canine'}
        subtitle={''}
        centered
        size={3}
      />

      <FacebookPost
        items={{
          left:
            'https://www.facebook.com/TheDivineCanineSC/posts/408024060116410',
          right:
            'https://www.facebook.com/TheDivineCanineSC/posts/418518025733680',
        }}
      />
      <FacebookPost
        items={{
          left:
            'https://www.facebook.com/TheDivineCanineSC/posts/407462823505867',
          right:
            'https://www.facebook.com/TheDivineCanineSC/posts/405504183701731',
        }}
      />
      <FacebookPost
        items={{
          left:
            'https://www.facebook.com/TheDivineCanineSC/posts/398641347721348',
          right:
            'https://www.facebook.com/TheDivineCanineSC/posts/390422418543241',
        }}
      />
    </div>
  );
}
