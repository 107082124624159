import React from 'react';

export default function PrivacyPolicy() {
  return (
    <div className={'container'}>
      <h1 className={'title'}>{'Privacy Policy of The Divine Canine LLC'}</h1>
      <br />
      <section className={'section'}>
        <div className={'container'}>
          <h2 className={'subtitle'}>
            The Divine Canine LLC operates the Website Name divinecaninellc.com,
            which provides information on for the Divine Canine dog grooming
            services. This page is used to inform website visitors regarding our
            policies with the collection, use, and disclosure of Personal
            Information if anyone decided to use our Service, the Website Name
            website. If you choose to use our Service, then you agree to the
            collection and use of information in relation with this policy. The
            Personal Information that we collect are used for providing and
            improving the Service. We will not use or share your information
            with anyone except as described in this Privacy Policy. The terms
            used in this Privacy Policy have the same meanings as in our Terms
            and Conditions, which is accessible at Website URL, unless otherwise
            defined in this Privacy Policy.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Information Collection and Use'}</h1>
          <p className={'subtitle is-spaced'}>
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you. We may collect and combine information when you
            interact with the Divine Canine LLC services including:
          </p>
          <div className={'content'}>
            <ol type={'1'}>
              <li>
                <strong>{'Cookies.'}</strong>
                <br />
                Our website uses “cookies” to collect information and to improve
                our Service. You have the option to either accept or refuse
                these cookies, and know when a cookie is being sent to your
                computer.
              </li>
              <li>
                <strong>
                  With Partners: Advertising, Analytics and Business Partners
                  (Limited to Non-Personally Identifiable Information).
                  <br />
                </strong>
                We collect information from your devices (computers, mobile
                phones, tablets, etc.), including information about how you
                interact with our Services and those of our third-party partners
                and information that allows us to recognize and associate your
                activity across devices and Services. This information includes
                device specific identifiers and information such as IP address,
                cookie information, mobile device and advertising identifiers,
                browser version, operating system type and version, mobile
                network information, device settings, and software data. We may
                recognize your devices to provide you with personalized
                experiences and advertising across the devices you use.
              </li>
            </ol>
          </div>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Security'}</h1>
          <h2 className={'subtitle'}>
            We value your trust in providing us with your Personal Information,
            thus we are striving to use commercially acceptable means of
            protecting it. But remember that no method of transmission over the
            Internet, or method of electronic storage is 100% secure and
            reliable, and we cannot guarantee its absolute security.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Links to Other Sites'}</h1>
          <h2 className={'subtitle'}>
            Our Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
            <br />
            <br />
            Children&apos;s Privacy
            <br />
            Our Services do not address anyone under the age of 13. We do not
            knowingly collect personal identifiable information from children
            under 13. In the case we discover that a child under 13 has provided
            us with personal information, we immediately delete this from our
            servers. If you are a parent or guardian and you are aware that your
            child has provided us with personal information, please contact us
            so that we will be able to do the necessary actions.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Changes to This Privacy Policy'}</h1>
          <h2 className={'subtitle'}>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. We will notify
            you of any changes by posting the new Privacy Policy on this page.
            These changes are effective immediately after they are posted on
            this page.
          </h2>
        </div>
      </section>

      <section className={'section'}>
        <div className={'container'}>
          <h1 className={'title'}>{'Contact Us'}</h1>
          <h2 className={'subtitle'}>
            The Divine Canine LLC
            <br />
            31 N Main St Inman, SC 29349
          </h2>
        </div>
      </section>
    </div>
  );
}
