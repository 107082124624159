import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function NavMenu(props) {
  return (
    <div className={'navbar-end'}>
      <Link onClick={props.onClick} to={'/'}>
        <div className={'navbar-item is-secondary'}>
          <span style={{ marginLeft: '.5em' }}>{'Home'}</span>
        </div>
      </Link>

      <Link onClick={props.onClick} to={'/location'}>
        <div className={'navbar-item is-secondary'}>
          <span style={{ marginLeft: '.5em' }}>{'Contact'}</span>
        </div>
      </Link>

      <Link onClick={props.onClick} to={'/services'}>
        <div className={'navbar-item is-secondary'}>
          <span style={{ marginLeft: '.5em' }}>{'Services'}</span>
        </div>
      </Link>
    </div>
  );
}

export default NavMenu;
