import React from 'react';

export default function FacebookPost(props) {
  return (
    <div
      style={{ marginTop: '5em', marginBottom: '5em' }}
      className={'container'}
    >
      <div className={'columns is-spaced'}>
        <div className={'column is-6'}>
          <div className={'is-spaced'}>
            <div className={'fb-post'} data-href={props.items.left}></div>
          </div>
        </div>
        <div className={'column is-6'}>
          <div className={'is-spaced'}>
            <div className={'fb-post'} data-href={props.items.right}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
