import './styles.scss';
import './pace.min';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import NavBarContainer from './components/NavbarContainer';
import MapContainer from './components/MapContainer/MapContainer';
import Footer from './components/Footer';
import Services from './components/Services/Services';
import PrivacyPolicyMain from './components/privacy-policy/PolicyMain';
import ConsentNotification from './components/ConsentNotification';
import '@fortawesome/fontawesome-free/css/all.css';
import FacebookPostSection from './components/Social';
import history from './utils/history';
import ScrollToTop from './utils/RouteHistoryEffect';

class AppRouter extends React.Component {
  render() {
    return (
      <div>
        <Router history={history} basename={'/'}>
          <NavBarContainer spaced color={'white'} />

          <ScrollToTop />

          <Route path={'/'} exact component={App} />
          <Route path={'/location'} component={MapContainer} />
          <Route path={'/services'} component={Services} />
          <Route path={'/privacy-policy'} component={PrivacyPolicyMain} />
          <Route path={'/social'} component={FacebookPostSection} />

          <ConsentNotification
            cookieName={'DivineCanineFBAnalytics'}
            cookieValue={'agreed'}
          />

          <Footer />
        </Router>
      </div>
    );
  }
}

ReactDOM.render(<AppRouter />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (process.env.NODE_ENV === 'production') {
  serviceWorker.unregister();
}
