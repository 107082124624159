import React from 'react';
import SectionHeader from '../SectionHeader';
import config from './config';
import store from '../assets/store-wide.jpg';
import './styles.scss';

function ServicePresentationSection() {
  return (
    <div className={'hero is-large is-transparent has-background'}>
      <img
        alt={'Divine Canine Store Wide View'}
        className={'hero-background is-transparent light'}
        src={store}
      />
      <div className={'hero-body'}></div>
    </div>
  );
}

function ServiceColumn(props) {
  return (
    <div className={`column is-${props.size}`}>
      <h4 className={`title is-4 has-text-${props.color}`}>
        <i className={props.icon} style={{ marginRight: '0.4em' }}></i>{' '}
        {props.title}
      </h4>
      <p className={'block'}>{props.description}</p>
    </div>
  );
}

function IncludedInFullServiceColumn(props) {
  return (
    <div className={`column is-${props.size}`}>
      <span className={`tag is-${props.color} is-${props.tagSize}`}>
        {props.tag}
      </span>
      <div className={'content'}>
        <ul>
          {props.included.map((item, index) => (
            <li key={index} className={'is-size-7'}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

function ServiceFull() {
  const services = config.get('services');
  const full = services.service.full;
  const included = services.includedServices.included;
  return (
    <div className={'section has-background-light has-text-dark'}>
      <div style={{ marginTop: '3em' }}>
        <SectionHeader
          title={'Services'}
          subtitle={
            'We offer a complete set of services to help you keep your dog happy and healthy.'
          }
          centered
          size={3}
        />
        <div className={'container'} style={{ marginTop: '5em' }}>
          <div className={'columns is-centered is-spaced'}>
            <div className={'column is-10'}>
              <div className={'columns'}>
                <ServiceColumn
                  size={6}
                  color={'dark'}
                  icon={'fas fa-hot-tub'}
                  title={full.baths.name}
                  description={full.baths.description}
                />
                <ServiceColumn
                  size={6}
                  color={'dark'}
                  icon={'fas fa-wind'}
                  title={full.grooming.name}
                  description={full.grooming.description}
                />
              </div>
            </div>
          </div>
          <div className={'columns is-centered is-spaced'}>
            <div className={'column is-10'}>
              <div className={'columns'}>
                <IncludedInFullServiceColumn
                  size={6}
                  color={'dark'}
                  tag={'Services Include'}
                  tagSize={'normal'}
                  included={included}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ServiceAddOn() {
  const services = config.get('services');
  const addons = services.service.addons;
  return (
    <div className={'section has-background-primary-alt has-text-white'}>
      <div className={'container'}>
        <div className={'section-header has-text-centered'}>
          <h2 className={'title is-size-2-desktop has-text-white'}>Addons</h2>
          <h3 className={'header-subtitle has-text-white'}>
            Treat your pup to some extra loving care
          </h3>
        </div>

        <div className={'columns is-centered'}>
          <div className={'column is-9'}>
            <div className={'columns'}>
              <ServiceColumn
                size={6}
                color={'white'}
                icon={'fas fa-hot-tub'}
                title={addons[0].name}
                description={addons[0].description}
              />
              <ServiceColumn
                size={6}
                color={'white'}
                icon={'fas fa-wind'}
                title={addons[1].name}
                description={addons[1].description}
              />
            </div>
            <div className={'columns'}>
              <ServiceColumn
                size={6}
                color={'white'}
                icon={'fas fa-paw'}
                title={addons[2].name}
                description={addons[2].description}
              />
              <ServiceColumn
                size={6}
                color={'white'}
                icon={'fas fa-soap'}
                title={addons[3].name}
                description={addons[3].description}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function Services() {
  return (
    <div>
      <ServiceFull />
      <ServicePresentationSection />
      <ServiceAddOn />
    </div>
  );
}
