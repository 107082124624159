import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';

function PresentationCardBody(props) {
  if (props.to) {
    return (
      <p>
        <Link to={props.to}>{props.link}</Link> {props.body}
      </p>
    );
  } else {
    return <p>{props.body}</p>;
  }
}

export default function PresentationCard(props) {
  return (
    <div className={'flex-card light-bordered raised presentation-card'}>
      <span className={props.iconColor}>
        <i className={props.icon}></i>
      </span>

      <div style={{ marginLeft: '3em' }} className={'presentation-text'}>
        <h3 className={'title is-4 text-bold'}>{props.title}</h3>
        <p>
          <PresentationCardBody
            to={props.to}
            link={props.link}
            body={props.body}
          />
        </p>
      </div>
    </div>
  );
}
