import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './Terms';

class PrivacyPolicyMain extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      active: 0,
      policyClass: 'is-active',
      termsClass: '',
    };
  }

  showPolicy() {
    this.setState({
      active: 0,
      policyClass: 'is-active',
      termsClass: '',
    });
  }

  showTerms() {
    this.setState({
      active: 1,
      termsClass: 'is-active',
      policyClass: '',
    });
  }

  render() {
    const PolicyInfo = () => {
      if (this.state.active === 0) {
        return <PrivacyPolicy />;
      }
      return <TermsOfUse />;
    };

    return (
      <div id={'policy'}>
        <div style={{ position: 'relative', top: '0' }}>
          <section className={'hero'}>
            <div className={'hero-body'}>
              <div className={'container'}>
                <h1 className={'title'}>{'Privacy Policy - Terms Of Use'}</h1>
              </div>
            </div>
          </section>
          <div style={{ position: 'relative' }} className={'container'}>
            <div className={'tabs'}>
              <ul>
                <li className={this.state.policyClass}>
                  {/* eslint-disable-next-line */}
                  <a href='#' className='#' onClick={() => this.showPolicy()}>
                    Privacy Policy
                  </a>
                </li>
                <li className={this.state.termsClass}>
                  {/* eslint-disable-next-line */}
                  <a href={'#'} onClick={() => this.showTerms()}>
                    Terms Of Use
                  </a>
                </li>
              </ul>
            </div>
            <PolicyInfo />
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicyMain;
