import React from 'react';
import CookieReader from '../../utils/CookieReader';

export default class ConsentNotification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      render: false,
      consent: false,
      cookieName: null,
      cookieValue: null,
      consentMessage:
        'This site uses cookies to personalise content, to provide social media' +
        ' features, and to analyse traffic for security reasons.',
    };
  }

  componentDidMount() {
    if (!CookieReader().isCookiesEnabled()) {
      this.setState({
        render: false,
        consent: false,
      });
    } else if (CookieReader().hasCookie('DivineCanineFBAnalytics=agreed')) {
      this.setState({
        render: false,
        consent: true,
      });
    } else if (
      this.props['cookieName'] !== undefined &&
      this.props['cookieValue'] !== undefined
    ) {
      let msg = this.props['consentMessage'];
      if (msg !== undefined && typeof msg === 'string' && msg.length > 0) {
        this.setState({
          render: true,
          consent: false,
          cookieName: this.props['cookieName'],
          cookieValue: this.props['cookieValue'],
          consentMessage: this.props['consentMessage'],
        });
      } else {
        this.setState({
          render: true,
          consent: false,
          cookieName: this.props['cookieName'],
          cookieValue: this.props['cookieValue'],
        });
      }
    }
  }

  async setCookie() {
    return new Promise((resolve, reject) => {
      if (!CookieReader().isCookiesEnabled()) {
        reject(new Error('Cookies are not enabled'));
      } else if (
        this.state.cookieName === null ||
        this.state.cookieValue === null
      ) {
        reject(new Error('Cookies name and value must be set in props'));
      }

      CookieReader().setCookie(
        `${this.state.cookieName}=${this.state.cookieValue}`,
      );
      resolve();
    });
  }

  onConsent() {
    this.setCookie()
      .then(() => {
        this.setState({
          render: false,
          consent: true,
        });
      })
      .catch(err => {
        this.setState({
          render: false,
          consent: false,
        });
      });
  }

  render() {
    if (!this.state.render) {
      return null;
    }

    if (this.props.consent) {
      return null;
    }

    return (
      <div className={'container'}>
        <section
          id={'consent-section'}
          style={{ zIndex: 20, position: 'fixed', bottom: 0 }}
        >
          <div id={'consent-body'} className={'notification'}>
            {this.state.consentMessage}
            <div className={'buttons'}>
              <button
                onClick={() => this.onConsent()}
                id={'consent'}
                className={'button is-primary'}
              >
                <strong>{'Accept'}</strong>
              </button>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
