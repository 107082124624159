import React from 'react';

const { compose, withProps, withStateHandlers } = require('recompose');
const { InfoBox } = require('react-google-maps/lib/components/addons/InfoBox');
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} = require('react-google-maps');

const MapComponent = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAPS_KEY}`,
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '550px' }} />,
    mapElement: <div style={{ height: '100%' }} />,
    center: { lat: 35.048588, lng: -82.091004 },
  }),
  withStateHandlers(
    () => ({
      isOpen: true,
    }),
    {
      onToggleOpen: ({ isOpen }) => () => ({
        isOpen: !isOpen,
      }),
    },
  ),
  withScriptjs,
  withGoogleMap,
)(props => (
  <GoogleMap defaultZoom={20} defaultCenter={props.center}>
    <Marker
      position={{ lat: 35.048588, lng: -82.091004 }}
      onClick={props.onToggleOpen}
    >
      {props.isOpen && (
        <InfoBox
          onCloseClick={props.onToggleOpen}
          options={{ closeBoxURL: '', enableEventPropagation: true }}
        >
          <div style={{ backgroundColor: 'white', padding: '12px' }}>
            <div style={{ fontSize: '16px', fontColor: '#08233B' }}>
              <p className={'subtitle'}>{'31 N Main St Inman, SC 29349'}</p>
              <hr className={'divider'} />
              <a
                // prettier-ignore
                // eslint-disable-next-line
                rel='noopener noreferrer'
                target={'_blank'}
                href={
                  'https://www.google.com/maps/place/31+N+Main+St,+Inman,+SC+29349/@35.0485325,-82.091548,19z/data=!3m1!4b1!4m5!3m4!1s0x88577bd693151ef9:0x4ec6ce1cb97f2fec!8m2!3d35.0485314!4d-82.0910008'
                }
              >
                Open in Google Maps
              </a>
            </div>
          </div>
        </InfoBox>
      )}
    </Marker>
  </GoogleMap>
));

export default MapComponent;
