export default function CookieReader() {
  function isCookiesEnabled() {
    return window.navigator.cookieEnabled;
  }

  function deleteCookie(name) {
    if (name === undefined) {
      throw new Error('Cookie name must be defined');
    }

    const date = new Date();
    document.cookie = `${name};SameSite=Strict;path=/;expires=${date.now()}`;
  }

  function setCookie(name) {
    if (name === undefined) {
      throw new Error('Cookie name must be defined');
    }

    const date = new Date();
    date.setTime(date.getTime() + 24 * 60 * 60 * 1000 * 1000);
    document.cookie = `${name};SameSite=Strict;path=/;expires=${date.toUTCString()}`;
  }

  function hasCookie(cookieName) {
    return !!document.cookie
      .split(';')
      .filter(item => item.trim().startsWith(cookieName)).length;
  }

  return {
    isCookiesEnabled: isCookiesEnabled,
    setCookie,
    deleteCookie,
    hasCookie: hasCookie,
  };
}
