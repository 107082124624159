const convict = require('convict');

const config = convict({
  services: {
    includedServices: {
      included: [
        'Short Comfort walk around the salon before getting started.',
        'Warm massaging bath with premium shampoo and conditioner.',
        'Hand dry with fresh towel, followed by blow drying.',
        'Ears cleaned and nails trimmed. Paw pad hair on feet trimmed.',
        'Removal and brush out of loose and shedding hair.',
        'Quality grain-free treat when finished to emphasize a positive grooming experience.',
      ],
      inServiceType: 'full',
    },
    service: {
      full: {
        baths: {
          name: 'Baths',
          description:
            'Did somebody say spa day? Bring your dog in for their very own spa day at\
                    the divine canine! With our professional hydraulic lift bathtub, we can accommodate\
                    both small and large and get them in and out with ease leaving your fur baby relaxed\
                    and stress-free during bathing!',
        },
        grooming: {
          name: 'Full Grooming',
          description:
            'Specialized grooming specific to your dogs care and needs.\
                    Grooming prices vary based on breed, size, coat, condition, and temperament.\
                    An estimate can be given over the phone but we will need to meet your dog to give a firm price.',
        },
      },
      fullSubstitutions: {
        shampoo: [
          {
            name: 'Oat Meal Based Medicated Shampoo',
            description:
              'Oat-meal based, a fragrance free, and a medicated anti-itch shampoo. Please let us know\
                        if you would like one of these options instead of one of our scented premium brand shampoos.',
          },
        ],
      },
      addons: [
        {
          name: 'Furminating Treatment',
          description:
            'Special formulated de shedding shampoo and conditioner.\n35 - 45 min brush out.',
        },
        {
          name: 'Breath Refresh',
          description:
            'Stinky breath? Let us freshen up your dogs breath with our organic spearmint flavored gel,\
                    leaving them smelling like a breath of fresh air!',
        },
        {
          name: 'Paw Balm Application',
          description:
            'Ruff Paws? With our oatmeal based paw butter, your dogs paw pads will be massaged and\
                    lathered leaving them hydrated, crack free, and smelling like a fresh batch of oatmeal cookies!',
        },
        {
          name: 'Blueberry Facial',
          description:
            'An exhilarating facial for your dog that is an excellent way of getting rid of tear\
                    stains and enhancing the coats color naturally, providing a calming, aroma-therapeutic experience.',
        },
      ],
      standAlone: [
        {
          name: 'Nail Clipping',
          description: 'Nails clipped with traditional clippers.',
        },
        {
          name: 'Nail Grinding',
          description: 'Nails grinded with a dremel for a smoother finish.',
        },
        {
          name: 'Ear Cleaning',
          description: 'Ear cleaning for preventative care from infections.',
        },
        {
          name: 'Ear Plucking',
          description: 'Plucking of excess hair from ears.',
        },
      ],
    },
  },
});

export default config;
